<template>
  <div class="">
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/4 flex items-center font-c6">
        <icon
          :data="icons.user"
          height="20"
          width="20"
          class="mr-2 cursor-pointer"
          original
        />

        <div>{{ item.person.firstName }} {{ item.person.lastName }}</div>
      </div>

      <div class="w-1/6 ml-4 flex items-center">
        <span v-if="item.person.phone && item.person.phone !== item.person.email">{{ item.person.phone }}</span>
        <span v-if="!item.person.phone || item.person.phone === item.person.email">-</span>
      </div>

      <div class="w-1/4 ml-4">
        <span v-if="item.person.email"> {{ item.person.email }} </span>
        <span v-if="!item.person.email">-</span>
      </div>

      <div class="w-1/5 ml-4 flex items-center flex items-center">
        <span v-if="item.sfd">{{ item.sfd.name }}</span>
        <span v-if="!item.sfd">CCIB</span>
      </div>

      <div class="w-1/6 ml-4 text-center">
        Adminatrateur
      </div>
    </div>
  </div>
</template>

<script>
import user from '../../../assets/icons/ic-clientAv.svg'
import entreprise from '../../../assets/icons/ic-entreprise.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        user,
        entreprise
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
